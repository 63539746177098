import { Component, ViewChild, Inject, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SetUpDataService } from '../pages/set-up/services/set-up-data.service';
import { MessageService } from './../shared/services/message.service'


@Component({
	selector: 'app-mdms-dialog',
	templateUrl: './mdms-dialog.component.html',
	styleUrls: ['./mdms-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MdmsDialogComponent {
	isHoveredDownload: boolean = false;  // Flag for hover state of download button
	isHoveredUpload: boolean = false;
	alertDict: any;
	fileName: string = '';
	fileSelected: boolean = false;
	invalidFileType: boolean = false;
	invalidFileSize: boolean = false;
	invalidFileName: boolean = false;
	fileNotAvailable: boolean = false;
	allowedFileSize: number = 104857600; //Bytes = 100 MB
	allowedFileTypes: string[] = ['xls', 'xlsx', 'csv', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xlt', 'xlam']
	fileStatus: boolean = false;
	file: File;
	latestVersion: string = 'v1.0.0';
	lastUpdatedBy: string = 'Rahul Raheja';

	date = new Date().toLocaleDateString('en-US', {
		weekday: 'short',  // Mon
		year: 'numeric',   // 2024
		month: 'short',    // Sep
		day: 'numeric'     // 2
	});
	time =  new Date().toLocaleTimeString(); 
	lastUpdatedOn: string = `${this.date}, ${this.time}`;


	constructor(@Inject(DOCUMENT) private document: Document, private api: SetUpDataService, private message: MessageService, private cdr: ChangeDetectorRef) {
	}



	ngOnInit(): void {
		this.preloadImages();

	}
	preloadImages() {
		const images = [
			'./../../../../assets/images/icons/mdmsLoadHover.svg',
			'./../../../../assets/images/icons/mdmsLoad.svg',
			'./../../../../assets/images/icons/mdmsUploadHover.svg',
			'./../../../../assets/images/icons/mdmsUpload.svg'
		];
		images.forEach((src) => {
			const img = new Image();
			img.src = src;
		});
	}



	getStaticPresignedURL() {
		this.api.getStaticPresignedURLForMdms("Mdms")
			.subscribe({
				next: (res) => {
					let url = res["Response"]["PresignedURL"];
					const link = document.createElement('a');
					link.setAttribute('target', '_blank');
					link.setAttribute('href', url);
					link.setAttribute('download', `template.csv`);
					document.body.appendChild(link);
					link.click();
					link.remove();
				},
				error: (e) => {
					let url = "https://aws-a0106-use1-00-d-s3b-shrd-ofs-data01.s3.amazonaws.com/Country/Format/CountryFormat.xlsx?AWSAccessKeyId=ASIA3T6DVFZ4BULULKYK&Signature=SsUK0P7oZUDM1hcG3WV4YofEwN4%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEIz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIBL1Iclinlo3rZAlfNsnLV1YmtvzFSV%2FN10kxX8rBiQZAiAPdaDAbAzE3dzlZyr%2FYBCyxmmMjrQSfxizK2cxv5b0nSqsBAiF%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAMaDDc5ODczNzM3ODkzNiIM9DZ%2BMNo6xlYXcVTIKoAE6rPM1%2FDUvLE2VPucyGXbiJeuMlnqgt5keKngx2Mk4LxU%2FzuAG4G3O0OBgfzsXL%2BfqASNr%2BiDi2sKAxtOXvoF%2BalqF03UwXxCb1DCG%2B6VvkqhmNi8I2SnPwcjbz8lNehk4DtkvsA3HGc06lGohbNHrP2BlEL2voHgmkRd0NGnVMxYkMmdBc7oPF1Qhp4T29LKcvrwXl2KD1JkrLdF1OpWEJaGbAk%2BE1Y1vaDD%2ByUieEZTiNHeekfW8rgsE%2FuSFbN8TZWBq1NrnI0YmliJM3j3y8pF7IB1UWZjbjcAAFIxxca9FfkJydwD4sa6FrTZnv5gSmEW1m5kex8u0p99OWu5RGpNxjkCQku9f9LIubVfOHp3JvixtriZbaLAjy2uSrCsmuFRgziIcva1UxaKqW%2FL9F8I3AbUGLgWktXep5B1Qfa5WEffonEh3BOREgKmyxBUUYnruvMMKrmqAM7I1FgQslEczYwg%2BmY5IuayMHLlOr%2BqrOrYLSSCmDMjzhZ9ctB0NeFZu4phRPMg88W7wow7Rhm6sa0chR481wW34e%2FU%2Bi4X0uk3t9iNVx2E221A2GdjxhW5TYG%2FAI8YXQqA0XgOu4erjWgnqPZAAd5UwrdCfxru6efBq5eGZia%2BFHvc%2BS2ALpkpEPH3VntQDc4Yi7uv6LGMswQv%2BNpWGgADvIY2L4Uw6u%2BCogY6pwF91%2B%2FxLDekCObkKxbQ6KKgrHEsK9NW%2BXivbbCWgx1ESJYhQLLTV%2F91uJyEnmoLLhmhZwHhUfNXBj%2FhtYfCqqJ30TIggRTnwEwanao2V8JNd5taRNSx5ZiwMMa1eEjz6X0h528VAejQJ6mAoj3QjHAfrXYUFMpTv7tSH6qwjvM8UuVWUbi6zECoJTcBrkx1dKOUl3ssesdURF9kEeyhX6AhbixfGLcizw%3D%3D&Expires=1682056978"
					const link = document.createElement('a');
					link.setAttribute('target', '_blank');
					link.setAttribute('href', url);
					link.setAttribute('download', `products.csv`);
					document.body.appendChild(link);
					link.click();
					link.remove();
				}
			})
	}

	/**
	 * Function to check file type and upload 
	 * @param event event
	 */
	onFileSelected(event) {
		this.file = event.target.files[0];
		let filenamepattern = /^[^.]*\.[^.]*$/;
		const file: File = event.target.files[0];
		if (file) {
			this.fileName = file.name;
			this.fileSelected = true;
		} else {
			this.fileSelected = false;
		}
		if (!filenamepattern.test(this.file.name)) {
			this.invalidFileName = true;
		}
		else if (this.file && this.allowedFileTypes.includes(this.file.name.split('.').pop())) {
			if (this.allowedFileSize >= this.file.size) {
				this.fileName = this.file.name;
				this.fileStatus = true;
				this.fileNotAvailable = false;
				const formData = new FormData();
				this.invalidFileSize = false;
				formData.append("thumbnail", this.file);
				this.invalidFileType = false;
			}
			else {
				this.fileName = '';
				this.invalidFileSize = true;
			}
			this.invalidFileName = false;
		}
		else if (!this.allowedFileTypes.includes(this.file.name.split('.').pop())) {
			this.fileName = '';
			this.invalidFileType = true;
			this.invalidFileName = false;
		}
	}


}
