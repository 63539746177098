import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RedirectPopupComponent } from './shared/common/redirect-popup/redirect-popup.component';
import { SessionExpiredServiceService } from './shared/services/session-expired-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'FORWARD';

  showSessionExpiredPopup = false;

  constructor(private sessionExpiredService: SessionExpiredServiceService, public dialog: MatDialog) { }

  ngOnInit() {
    this.sessionExpiredService.sessionExpiredPopup.subscribe((show) => {
      this.showSessionExpiredPopup = show;
      if (show) {
        this.dialog.open(RedirectPopupComponent, {
          width: '650px',
          hasBackdrop: true,
          backdropClass: 'dialog-backdrop',
          disableClose: true,
        });
      }
    });
  }

  onClose() {
    this.showSessionExpiredPopup = false;
  }

}
