import { EventEmitter, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Router, Event, NavigationEnd } from '@angular/router';
import { tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SessionExpiredServiceService } from './session-expired-service.service';
@Injectable({
  providedIn: 'root'
})
export class AppInterceptor implements HttpInterceptor {
  constructor(private router: Router, private sessionExpiredService: SessionExpiredServiceService) { }
  sessionExpired = new EventEmitter<boolean>();

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    var req = request.clone({
      withCredentials: true,

    });
    if (!request.url.toString().toLowerCase().includes('/getloggeduserdetails')) {
      // Clone the request and set the new headers
      req = request.clone({
        setHeaders: {
          'EmpRole': JSON.parse(localStorage.getItem('loggedInUserRole'))
        },
        withCredentials: true,
      });

    }

    return next.handle(req).pipe(
      tap({
        // Succeeds when there is a response; ignore other events
        next: (event) => {
        },
        // Operation failed; error is an HttpErrorResponse
        error: (error) => {
          if (error.status == '401') {
            this.sessionExpiredService.show();
            window.location.href = environment.idmLoginUrl;
          }
        },
      })
    );
  }
}
