import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;
// const API_URL = "http://127.0.0.1:8080"
interface apiResponse {
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class SetUpDataService {

  constructor(private http: HttpClient) { }
  stringifiedData: any;
  parsedJson: any;

  getUniqueModelId() {
    let url = API_URL + "/getModelId";
    return this.http.get<any>(url);
  }

  getUserAccess() {
    let url = API_URL + "/getTeamUser";
    return this.http.get<any>(url);
  }

  toggleUserActive(data: any) {
    let url = API_URL + "/TeamUserDisable";
    return this.http.post<any>(url, data);
  }
  updateuserAccess(data: any) {
    let url = API_URL + "/editTeamUser";
    return this.http.post<any>(url, data);
  }



  getModelBIId() {
    let url = API_URL + "/getpopulatedropdown";
    return this.http.get<any>(url);
  }

  postTherapy(data: any) {
    let url = API_URL + "/addTherapyArea";
    return this.http.post<any>(url, data);

  }

  updateTherapy(data: any) {
    let url = API_URL + "/editTherapyArea";
    return this.http.post<any>(url, data);
  }

  getTherapy() {

    let url = API_URL + "/getTherapyAreaDetails";
    return this.http.get<any>(url);
  }

  postBU(data: any) {
    let url = API_URL + "/addBusinessUnit";
    return this.http.post<any>(url, data);

  }

  updateBU(data: any) {
    let url = API_URL + "/editBusinessUnit";
    return this.http.post<any>(url, data);
  }

  getBU() {

    let url = API_URL + "/getBusinessUnitDetails";
    return this.http.get<any>(url);
  }
  editTeam(data: any) {
    let url = API_URL + "/editTeam";
    return this.http.post<any>(url, data);
  }
  reuploadModelVersion(data: any) {
    let url = API_URL + "/modelReUpload";
    return this.http.post<any>(url, data);
  }

  postSC(data: any) {
    let url = API_URL + "/addScenario";
    return this.http.post<any>(url, data);

  }

  updateSC(data: any) {
    let url = API_URL + "/editScenario";
    return this.http.post<any>(url, data);
  }

  getSC() {

    let url = API_URL + "/getScenario";
    return this.http.get<any>(url);
  }


  deleteTherapy(data: any) {
    let url = API_URL + "/deleteTherapyArea";
    return this.http.post<any>(url, data);
  }
  // INDICATION
  postIndication(data: any) {
    let url = API_URL + "/addIndication";
    return this.http.post<any>(url, data);

  }

  updateIndication(data: any) {
    let url = API_URL + "/editIndication";
    return this.http.post<any>(url, data);
  }

  getIndication() {
    let url = API_URL + "/getIndicationDetails";
    return this.http.get<any>(url);
  }

  deleteIndication(data: any) {
    let url = API_URL + "/deleteIndication";
    return this.http.post<any>(url, data);
  }


  // BRANDS // 
  postBrand(data: any) {
    let url = API_URL + "/addBrand";
    return this.http.post<any>(url, data);

  }

  updateBrand(data: any) {
    let url = API_URL + "/editBrand";
    return this.http.post<any>(url, data);
  }

  getBrand() {
    let url = API_URL + "/getBrandDetails";
    return this.http.get<any>(url);
  }

  deleteBrand(data: any) {
    let url = API_URL + "/deleteBrand";
    return this.http.post<any>(url, data);
  }


  // USER //

  postUser(data: any) {
    let url = API_URL + "/addUser";
    return this.http.post<any>(url, data);

  }

  updateUser(data: any) {
    let url = API_URL + "/editUser";
    return this.http.post<any>(url, data);
  }

  getUser() {
    let url = API_URL + "/getUserDetails";
    return this.http.get<any>(url);
  }

  deleteUser(data: any) {
    let url = API_URL + "/deleteUser";
    return this.http.post<any>(url, data);
  }

  //
  private subject = new Subject<string>();
  sendMessage(message: string) {
    this.subject.next(message);
  }

  recievedMessage(): Observable<string> {
    return this.subject.asObservable();
  }

  // Team

  getTeam() {
    let url = API_URL + "/getTeamDetails";
    return this.http.get<any>(url);
  }

  getTeams() {
    let url = API_URL + "/getTeamGovernor";
    return this.http.get<any>(url);
  }

  getTeamsSuperAdmin() {
    let url = API_URL + "/getTeamSuperAdmin";
    return this.http.get<any>(url);
  }

  getTeamsAdmin() {
    let url = API_URL + "/getTeamDetailsAdmin";
    return this.http.get<any>(url);
  }

  // GEOGRAPHY //

  getRegion() {
    let url = API_URL + "/getRegionDetails";
    return this.http.get<any>(url);
  }

  getLanguage() {
    let url = API_URL + "/getLanguageDetails";
    return this.http.get<any>(url);
  }
  getCurrency() {
    let url = API_URL + "/getCurrencyDetails";
    return this.http.get<any>(url);
  }
  getCountry() {
    let url = API_URL + "/getCountryDetails";
    return this.http.get<any>(url);
  }

  postCountry(data: any) {
    let url = API_URL + "/addCountryMapping ";
    return this.http.post<any>(url, data);

  }

  addCountry(data: any) {
    let url = API_URL + "/addCountry ";
    return this.http.post<any>(url, data);

  }

  updateCountry(data: any) {
    let url = API_URL + "/editCountryMapping";
    return this.http.post<any>(url, data);
  }

  editCountry(data: any) {
    let url = API_URL + "/editCountry";
    return this.http.post<any>(url, data);
  }

  // getCountry(){
  //   let url = API_URL + "/getCountryDetails";
  //   return this.http.get<any>(url);
  // }

  deleteCountry(data: any) {
    let url = API_URL + "/deleteCountryMapping";
    return this.http.post<any>(url, data);
  }
  // getRegionDetails, getLanguageDetails, getCountryDetails, getCurrencyDetails

  getGeoCountry() {
    let url = API_URL + "/getGeoCountryDetails";
    return this.http.get<any>(url);
  }
  getGeoRegion() {
    let url = API_URL + "/getGeoRegionDetails";
    return this.http.get<any>(url);
  }

  updateRegion(data: any) {
    let url = API_URL + "/editRegionMapping";
    return this.http.post<any>(url, data);
  }

  postRegion(data: any) {
    let url = API_URL + "/addRegionMapping ";
    return this.http.post<any>(url, data);

  }
  deleteRegion(data: any) {
    let url = API_URL + "/deleteRegionMapping";
    return this.http.post<any>(url, data);
  }

  // MODEL MANAGEMENT //

  postModel(data: any) {
    let url = API_URL + "/addModel";
    return this.http.post<any>(url, data);

  }


  postExchangeRate(data: any) {
    let url = API_URL + "/addExchangeRate";
    return this.http.post<any>(url, data);

  }

  getExchangeRate() {
    let url = API_URL + "/getExchangeRateData";
    return this.http.get<any>(url);
  }

  postUserAccess(data: any) {
    let url = API_URL + "/addteamUser";
    return this.http.post<any>(url, data);

  }

  postTeam(data: any) {
    let url = API_URL + "/addteam";
    return this.http.post<any>(url, data);

  }

  updateModelVersion(data: any) {
    let url = API_URL + "/versionUp";
    return this.http.post<any>(url, data);
  }

  editModel(data: any) {
    let url = API_URL + "/editModel";
    return this.http.post<any>(url, data);
  }


  getModel() {
    let url = API_URL + "/getModelDetailsTest";
    return this.http.get<any>(url);
  }

  getTeamOptions() {
    let url = API_URL + "/getTeamOptions";
    return this.http.get<any>(url);
  }

  deleteModel(data: any) {
    let url = API_URL + "/deleteModel";
    return this.http.post<any>(url, data);
  }

  populateModel() {
    let url = API_URL + "/populateModel";
    return this.http.get<any>(url);
  }

  // Forecast Cycle // 
  postForecastCycle(data: any) {
    let url = API_URL + "/createForecastCycle";
    return this.http.post<any>(url, data);

  }

  getBIPairs() {
    let url = API_URL + "/populateForecast";
    return this.http.get<any>(url);
  }

  getPopulateTeamDropdown() {
    let url = API_URL + "/populateTeamDropdown";
    return this.http.get<any>(url);
  }

  cloneForecastCycle(data: any) {
    let url = API_URL + "/cloneForecastCycle";
    return this.http.post<any>(url, data);

  }

  updateForecastCycle(data: any) {
    let url = API_URL + "/saveDisableIsCurrent";
    return this.http.post<any>(url, data);
  }

  editForecastCycle(data: any) {
    let url = API_URL + "/editForecastCycle";
    return this.http.post<any>(url, data);
  }

  getForecastCycle() {
    let url = API_URL + "/getForecastCycleDetails";
    return this.http.get<any>(url);
  }

  deleteForecastCycle(data: any) {
    let url = API_URL + "/deleteForecastCycle";
    return this.http.post<any>(url, data);
  }

  getScenario() {
    let url = API_URL + "/getScenarioDetails";
    return this.http.get<any>(url);
  }

  getEnableBrand(forecastId) {
    let url = API_URL + "/getEnableBrand";

    const headerDict = {
      'ForecastCycleId': forecastId,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.http.get(url, requestOptions)
    // return this.http.get<any>(url);
  }
  getSubmissions(forecastId) {
    let url = API_URL + "/getSubmissionDetails";
    const headerDict = {
      'ForecastCycleId': forecastId,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.get<any>(url, requestOptions);
  }

  getSubmissionsForAnalyst(forecastId) {
    let url = API_URL + "/getSubmissionDetailsForAnalyst";
    const headerDict = {
      'ForecastCycleId': forecastId,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.get<any>(url, requestOptions);
  }

  uploadedFile(data) {
    let url = API_URL + "/uploadedFile";
    return this.http.post<any>(url, data);
  }

  downloadFile(data) {
    let url = API_URL + "/downloadFile";
    return this.http.post<any>(url, data);
  }

  populateFCEnableBrand(forecastId) {
    let url = API_URL + "/populateFCEnableBrand";
    const headerDict = {
      'ForecastCycleId': forecastId,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.get<any>(url, requestOptions);
  }

  populateGuidance(forecastId) {
    let url = API_URL + "/populateGuidance";
    const headerDict = {
      'ForecastCycleId': forecastId,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.get<any>(url, requestOptions);
  }

  postEnableBrand(data: any, forecastId: string) {
    let url = API_URL + "/addEnableBrand";

    const headerDict = {
      'ForecastCycleId': forecastId,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post<any>(url, data, requestOptions);

  }

  editEnableBrand(data: any, forecastId: string) {
    let url = API_URL + "/editEnableBrand";

    const headerDict = {
      'ForecastCycleId': forecastId,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post<any>(url, data, requestOptions);

  }

  postGuidance(data: any, forecastId: string) {
    let url = API_URL + "/addGuidance";

    const headerDict = {
      'ForecastCycleId': forecastId,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post<any>(url, data, requestOptions);

  }

  updateGuidance(data: any, forecastId: string) {
    let url = API_URL + "/editGuidance";

    const headerDict = {
      'ForecastCycleId': forecastId,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post<any>(url, data, requestOptions);

  }

  getGuidance(forecastId: string) {
    let url = API_URL + "/getGuidanceDetails";

    const headerDict = {
      'ForecastCycleId': forecastId,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.http.get<any>(url, requestOptions);
  }

  getUserUploadURL() {
    let url = API_URL + "/getUserUploadURL";
    return this.http.get<any>(url);
  }

  addBulkCountry(data: any) {
    let url = API_URL + "/addBulkCountry"
    return this.http.post<any>(url, data);
  }
  addBulkUser(data: any) {
    let url = API_URL + "/addBulkUser"
    return this.http.post<any>(url, data);
  }
  getStaticPresignedURL(headerVal: string) {
    let url = API_URL + "/getStaticPresignedURL";

    const headerDict = {
      'Module': headerVal,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.http.get<any>(url, requestOptions);
  }

  getStaticPresignedURLForExchangeRate(headerVal: string) {
    let url = API_URL + "/getStaticPresignedURLForExchangeRate";

    const headerDict = {
      'Module': headerVal,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.http.get<any>(url, requestOptions);
  }

  
  getStaticPresignedURLForMdms(headerVal: string) {
    let url = API_URL + "/getStaticPresignedURLForMdms";

    const headerDict = {
      'Module': headerVal,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.http.get<any>(url, requestOptions);
  }

  addEditMilestone(data: any) {
    let url = API_URL + "/addEditMilestone"
    return this.http.post<any>(url, data);
  }

  getMilestoneDetails(forecastId: string) {
    let url = API_URL + "/getMilestoneDetails";

    const headerDict = {
      'ForecastCycleId': forecastId,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.http.get<any>(url, requestOptions);
  }

  postPortfolio(data: any, forecastId: string) {
    let url = API_URL + "/addPortfolio"
    const headerDict = {
      'ForecastCycleId': forecastId,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post<any>(url, data, requestOptions);

    // return this.http.post<any>(url, data);
  }

  updatePortfolio(data: any) {
    let url = API_URL + "/editPortfolio"
    return this.http.post<any>(url, data);
  }

  populatePortfolio(forecastId) {

    let url = API_URL + "/populatePortfolio";
    const headerDict = {
      'ForecastCycleId': forecastId,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.get<any>(url, requestOptions);
  }

  deleteModelFail(data: any) {
    let url = API_URL + "/deleteModel"
    return this.http.post<any>(url, data);
  }

  getPortfolio(forecastId: string) {
    let url = API_URL + "/getPortfolio";
    const headerDict = {
      'ForecastCycleId': forecastId,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.get<any>(url, requestOptions);
  }

  getPortfoliMapping(forecastId: string, portfolioId: string) {
    let url = API_URL + "/getPortfolioMapping";
    const headerDict = {
      'ForecastCycleId': forecastId,
      'PortfolioId': portfolioId,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.get<any>(url, requestOptions);
  }

  editPortfolioMapping(data: any, forecastId: string, portfolioId: string) {
    let url = API_URL + "/editPortfolioMapping"
    const headerDict = {
      'ForecastCycleId': forecastId,
      'PortfolioId': portfolioId,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post<any>(url, data, requestOptions);
  }

  deletePortfolio(data: any, forecastId: string) {
    let url = API_URL + "/deletePortfolio";
    const headerDict = {
      'ForecastCycleId': forecastId,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post<any>(url, data);
  }

  getGuidePresignedURL() {

    let url = API_URL + "/getGuidePresignedURL";
    return this.http.get<any>(url);
  }

  getSubRegion() {
    let url = API_URL + "/getSubRegion";
    return this.http.get<any>(url);
  }



  getCluster() {
    let url = API_URL + "/getCustomCluster";
    return this.http.get<any>(url);
  }

  updateSubRegion(data: any) {
    let url = API_URL + "/editSubRegion";
    return this.http.post<any>(url, data);
  }
  updateCluster(data: any) {
    let url = API_URL + "/editCustomCluster";
    return this.http.post<any>(url, data);
  }
  postSubRegion(data: any) {
    let url = API_URL + "/addSubRegion ";
    return this.http.post<any>(url, data);
  }
  postCluster(data: any) {
    let url = API_URL + "/addCustomCluster ";
    return this.http.post<any>(url, data);
  }

  
  editExchangeRate(data: any) {
    let url = API_URL + "/editExchangeRate";
    return this.http.post<any>(url, data);

  }

  getPortfolioScenarios() {
    let url = API_URL + "/getScenarioForPortfolio";
    return this.http.get<any>(url);
  }

  getPortfolioCSV(data: any) {
    let url = API_URL + "/getPortfolioData";
    return this.http.post<any>(url, data);
  }

  getKPIs(data: any) {
    let url = API_URL + "/getKpiDetails";
    return this.http.post<any>(url, data);
  }

}
