<h1 mat-dialog-title class="fw-bolder fs-5">Sync Data From MDMS</h1>
<mat-icon class="close-dialog-icon" mat-dialog-close=""> close</mat-icon>
<mat-dialog-content class="mat-typography">
    <div class="row">
        <!-- Download Template Button -->
        <div class="col-6 mb-2">
            <button class="btn d-flex w-100 flex-column align-items-center mdmsBtn" (click)="getStaticPresignedURL()"
                (mouseover)="isHoveredDownload = true;" (mouseleave)="isHoveredDownload = false;">
                <mat-icon class="icon custom-icon" [svgIcon]="!isHoveredDownload ? 'mdms_load' : 'mdms_load_selected'"></mat-icon>
                <span>Download Template</span>
            </button>
        </div>

        <!-- Upload Updated Template Button -->
        <div class="col-6 mb-2">
            <button class="btn d-flex w-100 flex-column align-items-center mdmsBtn" (click)="fileUpload.click()"
                (mouseover)="isHoveredUpload = true;" (mouseleave)="isHoveredUpload = false;">
                <mat-icon class="icon custom-icon" [svgIcon]="!isHoveredUpload ? 'mdms_upload' : 'mdms_upload_selected'"></mat-icon>
                <span>Upload Updated Data</span>
            </button>
            <!-- Hidden file input -->
            <input type="file" class="file-input" accept=".csv, .xls, .xlsx, .xlsb, .xlsm, .xltx, .xltm, .xlt, .xlam"
                (change)="onFileSelected($event)" #fileUpload style="display: none;">


        </div>
        <!-- Display selected file name if a file is selected -->
        <p *ngIf="fileSelected" class="ms-1 mt-1 mb-0"><strong>Selected File:</strong> {{ fileName }}</p>
        <!-- Error messages -->
        <mat-error *ngIf="fileNotAvailable" class="ms-1 mt-1 mb-0">File is required.</mat-error>
        <mat-error *ngIf="invalidFileName" class="ms-1 mt-1 mb-0">Invalid filename. Please use exactly one dot in the
            filename.</mat-error>
        <mat-error *ngIf="invalidFileType" class="ms-1 mt-1 mb-0">Invalid file type, only Excel file types are
            allowed.</mat-error>
        <mat-error *ngIf="invalidFileSize" class="ms-1 mt-1 mb-0">File size exceeds the maximum limit of 100 MB.</mat-error>
    </div>
    <div class="mt-4">
        <p><strong> Latest Version: </strong> <span class="blue-text">{{ latestVersion }}</span></p>
        <p><strong> Last Updated By: </strong> <span class="blue-text">{{ lastUpdatedBy }}</span></p>
        <p><strong> Last Updated On: </strong> <span class="blue-text">{{ lastUpdatedOn }}</span></p>        
        
        <p>Use the 'Download Template' button to download the template for system setup.</p>
        <p>Click on 'Upload Updated Data' to upload an updated file.</p>
    </div>
</mat-dialog-content>