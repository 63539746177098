import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  private localStorageKey = 'loggedInUserDetails';
  private localStorageRoleKey = 'loggedInUserRole';

  constructor(private http: HttpClient) { }

  public login(user) {
    localStorage.removeItem(this.localStorageKey);
    localStorage.setItem(this.localStorageKey, JSON.stringify(user));
  }

  public getUserDetails(): string {
    const user = JSON.parse(localStorage.getItem(this.localStorageKey));
    return user;
  }

  public logout() {
    localStorage.clear();
    localStorage.removeItem(this.localStorageKey);
    localStorage.removeItem(this.localStorageRoleKey);
  }

  public isLoggedIn(): boolean {
    return !!localStorage.getItem(this.localStorageKey);
  }

  public setUserRole(role) {
    localStorage.setItem(this.localStorageRoleKey, JSON.stringify(role));
  }

  public setUserForecasts(role, forecasts) {
    // 
    if (role.includes('.')) {
      role = role.split('.').pop();
    }
    localStorage.setItem(role + '_forecasts', JSON.stringify(forecasts))
  }

  public getUserForecasts(role) {
    if (role.includes('.')) {
      role = role.split('.').pop();
    }
    const forecasts = JSON.parse(localStorage.getItem(role + "_forecasts"));
    return forecasts;
  }

  public getUserRole(): string {
    const role = JSON.parse(localStorage.getItem(this.localStorageRoleKey));
    return role ? role : 'roche.Analyst';
  }

  getLoggedInUserDetails() {
    return this.http.get<any>(this.apiUrl + '/getLoggedUserDetails');
  }

}
